.game-status{
    border: 1px solid #000;
    margin: 0px 20px;
}
.border-left-past-game{
    border-left: 0.5px solid #000;

}
.border-right-past-game{
    border-right: 0.5px solid #000;

}
.border-bottom-past-game{
    border-bottom: 0.5px solid #000;
}
.view-reward{
    font-size:10px;
    /* border-bottom: 0.5px solid #000; */
    padding: 0px 10px;    

}