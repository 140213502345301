/* Style for the modal overlay */
.modal-overlay-game {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style for the modal */
/* ../../assets/images/creativity.png */

.modal-game {
    position: relative;
    background-image: url('../../assets/images/creativity.png'); /* Replace 'your-image-url.jpg' with the URL of your desired image */
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .modal-game:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.85); /* Adjust the last value (0.5 in this case) to change the opacity (0 is transparent, 1 is opaque) */
    border-radius: 8px;
  }
  
  .modal-content-game {
    position: relative;
    z-index: 1;
  }
  

/* Style for the close icon */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 30px;
  color: #888;
}

.close:hover {
  color: #000;
}
