/* Style for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-height:70vh ; */
}

/* Style for the modal */
/* ../../assets/images/creativity.png */

.toast-modal {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0px 20px;
  min-width: 80%;
  /* max-height: 80%; */
  overflow-y: auto;
  overflow: scroll;
  max-height:70vh ;
}

.toast-modal:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Adjust the last value (0.5 in this case) to change the opacity (0 is transparent, 1 is opaque) */
  border-radius: 8px;
}
